//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LocalStorage } from "quasar";

export default {
    name: "TaskDetail",
    data() {
        return {
            showpop: false,
            userList:[],
            userDetail:{
                memberInfo:{
                    
                },
                blessingWords:[
                    
                ]
            },
            taskRecordInfo:{}
        };
    },

    mounted() {
        this.wxConfig();
        if(this.$route.query.id){
            this.getTaskDetail()
        }
        
    },
    methods: {
        getTaskDetail() {
            this.$toast.loading({
        message: "数据加载中",
        forbidClick: true,
        duration: 0,
      });
            this.axios
                .get("/" + this.$ajaxPrefix.worktask + "/wxcp/task/getRecord?id="+this.$route.query.id)
                .then((res) => {
                    console.log("任务", res);
                    this.$toast.clear();

                    if (res && res.data && res.data.data) {
                        this.userList = res.data.data.inputMap
                        this.userDetail.blessingWords=res.data.data.setDefaultList
                        this.taskRecordInfo=res.data.data.taskRecordInfo;
                        console.log('this.taskList', this.userList)
                    } else {
                        this.$toast({ message: "获取任务失败", duration: 5000 });
                    }
                });
        },
        showpopbox(item) {
            this.userDetail.memberInfo=item;
            this.showpop = true
        },
        wxConfig(callback) {
            let agentId = LocalStorage.getItem("agentId")
            let corpId = LocalStorage.getItem("corpId")
            this.axios
                .get("/" + this.$ajaxPrefix.consumer + "/v1/wx/cp/getJsSdkConfig", {
                    params: {
                        agentId,
                        corpId,
                        url: encodeURIComponent(window.location.href.split("#")),
                    },
                })
                .then((res) => {
                    if (res && res.data && res.data.data) {
                        const temp = res.data.data;
                        wx.config({
                            beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
                            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                            appId: temp.appId, // 必填，企业微信的corpID
                            timestamp: temp.timestamp, // 必填，生成签名的时间戳
                            nonceStr: temp.nonceStr, // 必填，生成签名的随机串
                            signature: temp.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
                            jsApiList: ["setClipboardData", "openEnterpriseChat"], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
                        });
                        wx.ready(function () {
                            callback && callback();
                        });
                    } else {
                        this.$toast({ message: "调用微信插件失败！", duration: 5000 });
                    }
                });
        },
        copysend(zftext) {
            var text = zftext;
            var that=this;
            that.sginPost();
            wx.setClipboardData({
                data: text,    // 设置的
                success: function (res) {
                    console.log(res.errMsg)
                    wx.openEnterpriseChat({
                        // 注意：userIds和externalUserIds至少选填一个。内部群最多2000人；外部群最多500人；如果有微信联系人，最多40人
                        userIds: '',    //参与会话的企业成员列表，格式为userid1;userid2;...，用分号隔开。
                        externalUserIds: that.userDetail.memberInfo.externalContactUserId, // 参与会话的外部联系人列表，格式为userId1;userId2;…，用分号隔开。
                        groupName: '',  // 会话名称。单聊时该参数传入空字符串""即可。
                        chatId: "",
                        success: function (res) {
                            console.log(res)
                            that.sginPost();
                            // 回调
                        },
                        fail: function (res) {
                            if (res.errMsg.indexOf('function not exist') > -1) {
                                alert('版本过低请升级')
                            }
                        }
                    });
                }
            })
        },
        sginPost(){
                 
            this.axios
                .post("/" + this.$ajaxPrefix.worktask + "/wxcp/task/changeRelationshipsStatus",{
                    recordId:this.userDetail.memberInfo.recordId,
                    modelId:this.userDetail.memberInfo.modelId,
                    model:this.userDetail.memberInfo.model,
                    status:0
                })
                .then((res) => {
                 console.log(res);
            
                });
        }
    }
}
